import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import ArticleCards from '../components/ArticleCards'
import EventCards from '../components/EventCards'
import HomeHeader from '../components/HomeHeader'
import LinkAsButton from '../components/LinkAsButton'
import NoResultsText from '../components/NoResultsText'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEO'
import { PageContext } from '../context/ApplicationState'

const SectionTitle = styled.h3`
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 40px;
  }
`

const MissionSection = styled.section`
  position: relative;
  color: white;
  background-color: ${props => props.theme.colors.primary};

  .gatsby-image-wrapper {
    width: 100%;
    height: 340px;
  }

  blockquote {
    max-width: 915px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-family: ${props => props.theme.typography.serifDisplay};
    font-size: 28px;
    line-height: 36px;

    &::before {
      content: none;
      /* width: 30px;
      height: 24px; */
    }
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .gatsby-image-wrapper {
      height: auto;
    }

    .wrap {
      display: flex;
      align-items: center;
      max-width: 680px;
      height: auto;
      padding: 80px 20px 80px 80px;
    }

    blockquote {
      margin: 0;
      font-size: 58px;
      line-height: 70px;

      &::before {
        width: 60px;
        height: 48px;
      }
    }
  }

  @media (min-width: ${props => props.theme.responsive.xLarge}) {
    &,
    .gatsby-image-wrapper {
      height: 600px;
    }
  }
`

const FoundationText = styled.div`
  background: #333;
  margin: auto;
  max-width: 1150px;
  padding: 15px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  @media (min-width: ${props => props.theme.responsive.large}) {
    padding: 20px;
    font-size: 28px;
    line-height: 30px;
  }

  span {
    color: #fff;
  }

  a {
    display: block;
    width: 200px;
    margin: auto;
  }
`

const Index = () => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Homepage" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
      allContentfulArticle(
        sort: { fields: [featured, publishDate], order: [DESC, DESC] }
        limit: 6
      ) {
        edges {
          node {
            ...ArticlesQuery
          }
        }
        totalCount
      }
      allContentfulCalendarEvent(
        filter: { calendar: { eq: "Maskcara Artists" } }
        sort: { fields: [startTime], order: [ASC] }
        limit: 10000
      ) {
        edges {
          node {
            title
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            startTime
            endTime
          }
        }
        totalCount
      }
      allContentfulAsset(
          filter: { description: { regex: "/.*homepage.*/" } }
          sort: { fields: description }
        ) {
        edges {
          node {
            fluid(
              sizes: "(max-width: 1200px)"
              quality: 80
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const articles = data.allContentfulArticle.edges
  const totalArticles = data.allContentfulArticle.totalCount
  const articlesToShow = 6
  const contentfulEvents = data.allContentfulCalendarEvent.edges
  const eventsToShow = 3
  const futureEvents = contentfulEvents
    .filter(({ node: event }) => dayjs(event.startTime) > dayjs())
    .slice(0, eventsToShow)
  const pageContext = data.allContentfulPageContext.edges[0].node
  const heroImgMobile = pageContext.heroImageMobile
  const heroImgDesktop = pageContext.heroImageDesktop
  const missionImage = data.allContentfulAsset.edges[0].node
  const foundationImage = data.allContentfulAsset.edges[1].node

  return (
    <>
      <SEO />

      <PageHeader
        page="home"
        // noParallax="true"
        imageMobile={heroImgMobile}
        imageDesktop={heroImgDesktop}
      >
        <HomeHeader />
      </PageHeader>
      <main>
        <section className="fill--nearly-white">
          <div className="container wrap padded-section">
            <SectionTitle className="section-title">Recent News</SectionTitle>
            {totalArticles > 0 ? (
              <ArticleCards className="homepage-layout" articles={articles} />
            ) : (
              <NoResultsText>We haven't added any news yet 😢</NoResultsText>
            )}
            {totalArticles > articlesToShow && (
              <LinkAsButton to="/news/" title="More News" />
            )}
          </div>
        </section>
        <section style={{ paddingBottom: '50px' }} className="fill--nearly-white">
          <SectionTitle style={{ padding: '0 15px' }} className="section-title--secondary">Make a Difference in the foster care community with Seint's Nonprofit Foundations</SectionTitle>
          <div>
            <Img style={{ margin: 'auto', maxWidth: '1150px', maxHeight: '620px' }} fluid={foundationImage.fluid} />
          </div>
          <FoundationText>
            <span>
              Find out how to get involved in Seint's nonprofit efforts
            </span>
            <br /><br />
            <a href="/foundation/" className="btn--pill">
              <span>Learn More</span>
            </a>
          </FoundationText>
        </section>
        <MissionSection>
          <Img fluid={missionImage.fluid} />
          <div className="wrap white-quote">
            <blockquote>
              <p>
                Helping others look beautiful is nice, but helping them believe they are beautiful is life changing!
              </p>
            </blockquote>
          </div>
        </MissionSection>
        {futureEvents.length > 0 && (
          <section className="container wrap padded-section">
            <SectionTitle className="section-title">
              Upcoming Events
            </SectionTitle>
            <EventCards showMonth="true" events={futureEvents} />
            {futureEvents > eventsToShow && (
              <LinkAsButton to="/events/" title="More Events" />
            )}
          </section>
        )}
      </main>
    </>
  )
}

export default Index
