import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

const Wrapper = styled.div`
  color: white;

  h1 {
    margin-top: 0;
    margin-bottom: 35px;
    font-size: 48px;
    line-height: 43px;
  }

  hr {
    opacity: 0.35;
  }

  > div {
    margin-top: 25px;
  }

  p {
    max-width: 644px;
    margin-bottom: 15px;
  }

  a {
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border: none;
  }

  @media screen and (min-width: ${theme.responsive.large}) {
    width: 100%;

    h1 {
      margin-bottom: 70px;
      font-size: 130px;
      line-height: 110px;
    }

    hr {
      margin-top: 70px;
      margin-bottom: 30px;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
    }

    p {
      font-size: 23px;
      line-height: 30px;
      margin-bottom: 0;
    }
  }
`

const HomeHeader = props => (
  <Wrapper {...props}>
    <h1>
      Hello,
      <br />
      Gorgeous!
    </h1>
    <hr />
    <div>
      <p>
        Welcome to the Seint family. We couldn’t be more excited to have you!
      </p>
      <Link to="/training/" className="btn--pill">
        Get Started
      </Link>
    </div>
  </Wrapper>
)

export default HomeHeader
